import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "src/context/AuthProvider";

/**
 * PublicRoute is accessible without authentication, if authenticated can resolve a default protected route if needed
 * @component
 * @param {component} component - the page component that the route resolve to
 * @param {component} layout - the layout used to display the component to
 */
const PublicRoute = ({ component: Component, redirectIfAuth = false, ...rest }) => {
    const { isAuth: isAuthenticated } = useContext(AuthContext);
    const Cmp = (props)=>{
        return (
            !isAuthenticated ? (<Component {...props} />) : (<Redirect to={`/add-client`} />) 
        );
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return redirectIfAuth ? <Cmp {...props}/> : (<Component {...props} />);
            }
            }
        />
    );
}
export default PublicRoute;