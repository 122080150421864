import React, { useState } from 'react';
import { useEffect } from 'react';

export const AuthContext = React.createContext({
    isAuth: false
});

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const loginHandler = async (data) => {
        //TODO implement login functionality
        const myPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                setIsAuthenticated(true);
                sessionStorage.setItem("isAuthenticated", true);
                resolve();
            }, 300);
        });
        return myPromise;
    };

    const logoutHandler = async (data) => {
        //TODO implement logout functionality
        const myPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                setIsAuthenticated(false);
                sessionStorage.removeItem("isAuthenticated");
                resolve();
            }, 300);
        });
        return myPromise;
    }

    useEffect(() => {
        const session = sessionStorage.getItem("isAuthenticated");
        if (session) {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isAuth: isAuthenticated,
                login: loginHandler,
                logout: logoutHandler
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;