import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "src/context/AuthProvider";

/**
 * PrivateRoute takes user to login if not authenticated yet but tries to access a protected route
 * @component
 * @param {component} component - the page component that the route resolve to
 * @param {component} layout - the layout used to display the component to
 */
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
    const { isAuth: isAuthenticated } = useContext(AuthContext);

    return (
        isAuthenticated ?
            <Route
                {...rest}
                render={(props) => {
                    return <Component {...rest} />;
                }
                }
            /> : <Redirect to={`/login`} />
       
    )
}

export default PrivateRoute;